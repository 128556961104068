import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function LocationPage(props) {
  return (
    <Layout {...props}>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Locatie"
      />
      <section className="">
        <h1 className="text-4xl pb-8">Locatie</h1>
        <iframe width="100%" height="480" style={{border: 0}} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9593.466130778383!2d5.8404964!3d53.0497213!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5d8bc2f2351b1ee0!2sVoetsalon+Akkrum!5e0!3m2!1snl!2snl!4v1437740316649" frameBorder="0" allowFullScreen=""></iframe>
      </section>
    </Layout>
  );
}

export default LocationPage;
